body {
    background-color: #f0f3f8; 
    /* background-image: url('../../assests/img/background.png');  */
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    margin: 0;
    min-height: 100vh;
    overflow: hidden;
}
.survey-container {
    max-width: 800px; 
    margin: 1.4rem auto; 
    padding: 1.4rem; 
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 75vh; 
    overflow-y: auto; 
    margin-top: -24px;
}

.survey-title {
    font-size: 1.4rem; 
    font-weight: 500;
    color: #202124;
    margin-bottom: 1.4rem; 
    padding-bottom: 0.7rem;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
}

.survey-form {
    display: flex;
    flex-direction: column;
    /* gap: 1.4rem;  */
}

.question-container {
    padding: 1.05rem; 
    border-radius: 8px;
    transition: box-shadow 0.2s;
}

.question-container:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.question-label {
    font-size: 0.7rem; 
    font-weight: 500;
    color: #202124;
    margin-bottom: 0.525rem; 
}

.response-input,
.response-textarea,
.response-select {
    width: 100%;
    padding: 0.525rem; 
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 0.665rem; 
    color: #202124;
    transition: border-color 0.2s;
}

.response-textarea {
    min-height: 56px; 
    resize: vertical;
}

.response-button {
    padding: 0.525rem 1.4rem; 
    background-color: #1a73e8;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.7rem; 
    opacity: 0.7;
    cursor: not-allowed;
    width: 70px; 
    height: 21px; 
    text-align: center;
    margin: 7px; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: opacity 0.2s ease;
}

.response-button:hover {
    opacity: 0.6;
}

.file-container {
    max-width: 210px; 
    margin-top: 0.35rem; 
}

.file-media {
    width: 100%;
    max-height: 140px; 
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.file-audio {
    width: 100%;
    max-width: 210px; 
}

.file-name {
    margin-top: 0.35rem; 
    font-size: 0.61rem; 
    color: #5f6368;
}

.file-link a {
    color: #1a73e8;
    text-decoration: none;
    font-size: 0.61rem; 
}

.file-link a:hover {
    text-decoration: underline;
}

/* .loading,
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
    font-size: 1.2rem;
    color: #5f6368;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    padding: 20px;
    text-align: center;
    position: absolute; 
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
} */


@media (max-width: 768px) {
    .survey-container {
        padding: 0.7rem; 
        margin: 0.7rem; 
    }

    .survey-title {
        font-size: 1.05rem; 
    }

    .question-container {
        padding: 0.7rem; 
    }

    .file-container {
        max-width: 70%;
    }
}
